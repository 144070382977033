<template>
  <div class="quiz_list">
    <BreadCrumb :items="breadCrumb" />
    <b-card no-body>
      <BookSnippet :book="book" :toLink="{name:'bke-book-view', params:{book_id}}" :imgCarousel="true" />
      <div class="row">
        <div class="col p-4">
          <EditTable primary-key="id" :items="quizzes" :fields="fields"
            :busy="loading" outlined striped hover selectable sort-icon-left
            @addNew="$router.push({name:'quiz-new', params:{book_id}})"
            @refresh="refreshQuizzes"
            @deleteSelected="OnDeleteSelected"
            @uploadCsv="OnUploadCsv"
          >
            <template v-slot:title>
              <h5>Quizzes List</h5>
            </template>
            <template v-slot:cell(id)="cell">
              <b-link :to="{name:'quiz-view', params:{book_id, quiz_id: cell.value}}" title="View Details">{{(cell.index + 1)}}</b-link>
            </template>
            <template v-slot:cell(question)="cell">
              <div class="question" v-html="cell.value" />
            </template>
            <template v-slot:cell(options)="cell">
              <ol start="0">
                <li v-for="(v, i) in cell.value" :key="i">{{v}}</li>
              </ol>
            </template>
            <template v-slot:cell(answers)="cell">
              {{ cell.value.join(', ') }}
            </template>
          </EditTable>
        </div>
      </div>

    </b-card>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import EditTable from '@/components/ui/EditTable.vue';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import BookSnippet from '@/views/public/book/components/BookSnippet.vue';
import getBookMixin from '@/mixins/getbook';

export default {
  mixins: [getBookMixin],
  components: {
    BreadCrumb, BookSnippet, EditTable,
  },

  data() {
    return {
      loading: false,
      book_id: this.$route.params.book_id,
      quizzes: [],
      fields: [
        { key: 'id', label: '#' },
        { key: 'question', tdClass: 'quiz_question', sortable: true },
        { key: 'options' },
        { key: 'answers' },
        { key: 'point', sortable: true },
        { key: 'author', sortable: true },
        { key: 'explanation', sortable: true },
      ],
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books List', to: { name: 'bke-book-list' } },
        { text: this.book_id, to: { name: 'bke-book-view', params: { book_id: this.book_id } } },
        { text: 'Quizzes List', active: true },
      ];
    },
    ...mapState('quiz', {
      allQuizzes: state => state.quizzes,
    }),
  },

  methods: {
    getQuizzes() {
      if (this.book_id && this.allQuizzes[this.book_id]) this.quizzes = this.allQuizzes[this.book_id];
      else this.refreshQuizzes();
    },
    refreshQuizzes() {
      this.loading = true;
      this.$store.dispatch('quiz/getAllQuizzesOfBook', this.book_id).then(() => {
        this.quizzes = this.allQuizzes[this.book_id] || [];
      }).catch(() => {}).finally(() => {
        this.loading = false;
      });
    },
    OnDeleteSelected(items) {
      this.loading = true;
      const quizIds = items.map(x => x.id);
      this.$store.dispatch('quiz/deleteQuizzes', { bookId: this.book_id, quizIds }).catch(() => {
        this.$toast.error('Failed to delete selected items. Try again later.');
      }).finally(() => {
        this.loading = false;
        this.getQuizzes(); // update new quizzes array
      });
    },
    OnUploadCsv(items) {
      this.$toast.info(`${items.length} item(s) will be imported`);
      // Todo: Add logic for importing CSV entries into the table.
    },
  },

  created() {
    this.getQuizzes();
  },

};
</script>

<style lang="stylus" scoped>

.quiz_list
  .quiz_question
    max-width 20rem
    .question
      overflow hidden
      >>> img
        max-width 5rem

</style>
